import React from 'react';
import * as R from 'ramda';
import { graphql } from 'gatsby';

import SEO from '../components/seo'
import Layout from '../components/layout';

//import loadable from '@loadable/component'
import importedComponent from 'react-imported-component';

const Restaurants = importedComponent(() => import('../components/Restaurants'));

const RestaurantPage = ({ data, location }) => {

  const { restaurantContent, restaurantCobaSite, restaurantCobaOpeningHours, brandSettings } = data

  const seoTitle = R.path(['name'], restaurantContent)
  const pageURL = location.href

  let restaurantHours

  if (restaurantCobaOpeningHours) {
    let arr = []

    restaurantHours = restaurantCobaOpeningHours.nodes.filter(node => node.type === 'ORGANIZATION').sort((a, b) => (a.dayOfWeek > b.dayOfWeek) ? 1 : -1)

    restaurantHours.map((day, i) => {
      arr.push({
        day: day.dayOfWeek,
        start: day.startTime,
        end: day.endTime,
      })
      return false
    })
  }

  const dayNames = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

  let openingHours = []

  restaurantHours.forEach(day => {
    openingHours.push({
      '@type': 'OpeningHoursSpecification',
      dayOfWeek: `http://schema.org/${dayNames[day.dayOfWeek - 1]}`,
      opens: `${day.startTime}:00`,
      closes: `${day.endTime}:00`
    })
  })

  return (
    <Layout>
      <SEO
        sitename={R.path(['nimi'], brandSettings)}
        seoTitle={seoTitle}
        someTitle={seoTitle}
        url={pageURL}
        jsonLd={{
          '@context': 'http://schema.org',
          '@type': 'Restaurant',
          'name': `${R.path(['nimi'], brandSettings)} ${R.path(['name'], restaurantContent)}`,
          'address': {
            '@type': 'PostalAddress',
            'streetAddress': R.path(['address'], restaurantCobaSite),
            'addressLocality': R.path(['city'], restaurantCobaSite),
            'postalCode': R.path(['postcode'], restaurantCobaSite)
          },
          'servesCuisine': R.path(['ravintolanTyyppi'], brandSettings),
          'openingHoursSpecification': openingHours
        }}
        pageTemplate="ravintola"
        dataLayer={true}
        service={R.path(['sivunTavoite'], restaurantContent)}
        cuisine={R.path(['ravintolanTyyppi'], brandSettings)}
        city={R.path(['city'], restaurantCobaSite)}
        goal={R.path(['liiketoimintaAlue'], restaurantContent)}
        asiakaspolku={R.path(['asiakaspolku'], restaurantContent)}
      />
      <Restaurants data={data} location={location} />
    </Layout>
  );
};

export default RestaurantPage;

// eslint-disable-next-line no-undef
// allCobaOpeningHoursExceptions must not be empty
export const pageQuery = graphql`
  query restaurantPageQuery($cobaID: String, $slug: String!, $brand: String!) {
    allRestaurants: allContentfulRavintola(filter: {ketju: {sivupolku: { eq: $brand }}}) {
      nodes {
        id
        name: naviOtsikko
        slug: sivupolku
        cobaID: cobaId
        freeText: vapaaTeksti {
          freeText: vapaaTeksti
        }
      }
    }
    allEvents: allContentfulTapahtuma(filter: {ketju: {sivupolku: { eq: $brand }}, ravintola: {sivupolku: { eq: $slug }}}) {
      nodes {
        id
        ajankohta
      }
    }
    restaurantContent: contentfulRavintola(ketju: {sivupolku: { eq: $brand }}, sivupolku: { eq: $slug }) {
      id
      name: naviOtsikko
      slug: sivupolku
      cobaID: cobaId
      freeText: vapaaTeksti {
        freeText: vapaaTeksti
      }
      sivunTavoite
      liiketoimintaAlue
      asiakaspolku
    }
    restaurantCobaSite: cobaSites(SITE_ID: { eq: $cobaID }) {
      cobaID: SITE_ID
      name: NAME
      address: POSTAL_ADDRESS
      postcode: POSTAL_CODE
      city: POSTAL_TOWN
      phone: PHONENUMBER
      lat: LATITUDE
      lon: LONGITUDE
      email: EMAIL_ADDRESS
    }
    restaurantCobaOpeningHours: allCobaOpeningHours(filter: { SITE_ID: { eq: $cobaID }}) {
      nodes {
        type: TYPE
        cobaID: SITE_ID
        name: ORGANIZATION_NAME
        openAllDay: OPEN_ALL_DAY
        dayOfWeek: DAY_OF_WEEK
        startTime: START_TIME(formatString: "HH:mm")
        endTime: END_TIME(formatString: "HH:mm")
      }
    }
    restaurantCobaOpeningHoursExceptions: allCobaOpeningHoursExceptions(filter: { SITE_ID: { eq: $cobaID }}) {
      nodes {
        type: TYPE
        cobaID: SITE_ID
        name: ORGANIZATION_NAME
        startDate: START_DATE
        startTime: START_TIME(formatString: "HH:mm")
        endDate: END_DATE
        endTime: END_TIME(formatString: "HH:mm")
      }
    }
    brandSettings: contentfulKetju(sivupolku: { eq: $brand }) {
      id
      nimi
      sivupolku
      ravintolanTyyppi
      ravintoloidenYlasivu {
        sivupolku
      }
    }
  }
`;

